import { Directive, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';


@Directive({
  selector: '[appInputMaxLengthValidator]',
  inputs: ['appInputMaxLengthValidator.useTranslationKey', 'appInputMaxLengthValidator.trigger'],
  exportAs: 'appInputMaxLengthValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputMaxLengthValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)}
  ]
})
export class InputMaxLengthValidatorDirective extends BaseValidatorDirective implements OnInit, OnDestroy {

  protected _thisSelector = 'appInputMaxLengthValidator';
  protected override translationKey = 'validation.input.appInputMaxLengthValidator';

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  private _appInputMaxLengthValidator: number = 128;

  @Input()
  set appInputMaxLengthValidator(value: number) {
    this._appInputMaxLengthValidator = value;

    this.updateTranslation({n: value});
  }

  get appInputMaxLengthValidator(): number {
    return this._appInputMaxLengthValidator;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.updateTranslation({n: this.appInputMaxLengthValidator});

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    if (this.host) {
      this.host.hasRequiredValidator = false;
    }
  }

  validator: ValidatorFn = (control) => {
    const errors: ValidationErrors = {'appInputMaxLengthValidator': this.translation};
    const len = (control.value as string)?.length;
    return (len <= this.appInputMaxLengthValidator) ? null : errors;
  };

}
